var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-divider',{staticClass:"dividerTitle text-outline"},[_vm._v(_vm._s(_vm.$t("pages.registration.title")))]),_c('SocialLogin'),_c('div',{style:({ 'margin-left': '20px', 'margin-right': '20px' })},[_c('a-form',{staticClass:"registration-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"subtitle text-outline",style:({
          'margin-left': 'auto !important',
          'margin-right': 'auto !important',
          width: 'fit-content',
        })},[_vm._v(" "+_vm._s(_vm.$t("pages.registration.subtitle"))+" ")]),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":"E-mail"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: this.$t('ui.provideValidEmail'),
                },
                {
                  required: true,
                  message: this.$t('ui.ProvideEmail'),
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: this.$t('ui.provideValidEmail'),\n                },\n                {\n                  required: true,\n                  message: this.$t('ui.ProvideEmail'),\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":_vm.$t('ui.Password')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('ui.ProvidePassword'),
                },
                {
                  validator: _vm.validateToNextPassword,
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('ui.ProvidePassword'),\n                },\n                {\n                  validator: validateToNextPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"type":_vm.pwdVisible ? undefined : 'password'}},[_c('a-icon',{attrs:{"slot":"addonAfter","type":_vm.pwdVisible ? 'eye' : 'eye-invisible'},on:{"click":function($event){_vm.pwdVisible = !_vm.pwdVisible}},slot:"addonAfter"})],1)],1),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":_vm.$t('ui.Password')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('ui.ConfirmPassword'),
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
            },
          ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('ui.ConfirmPassword'),\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"type":_vm.pwdVisible ? undefined : 'password'},on:{"blur":_vm.handleConfirmBlur}},[_c('a-icon',{attrs:{"slot":"addonAfter","type":_vm.pwdVisible ? 'eye' : 'eye-invisible'},on:{"click":function($event){_vm.pwdVisible = !_vm.pwdVisible}},slot:"addonAfter"})],1)],1),_c('a-form-item',_vm._b({staticClass:"text-outline"},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.$t("ui.FirstName"))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'firstname',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('ui.ProvideFirstName'),
                  whitespace: true,
                },
              ],
            },
          ]),expression:"[\n            'firstname',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('ui.ProvideFirstName'),\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',_vm._b({staticClass:"text-outline"},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.$t("ui.SecondName"))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'secondname',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('ui.ProvideSecondName'),
                  whitespace: true,
                },
              ],
            },
          ]),expression:"[\n            'secondname',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('ui.ProvideSecondName'),\n                  whitespace: true,\n                },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.$t("ui.gender"))+" ")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'gender',
            {
              rules: [
                { required: true, message: _vm.$t('ui.ProvideYourGender') },
              ],
            },
          ]),expression:"[\n            'gender',\n            {\n              rules: [\n                { required: true, message: $t('ui.ProvideYourGender') },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('ui.PleaseProvideYourGender')}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(_vm._s(_vm.$t("ui.GenderMale")))]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t("ui.GenderFemale")))])],1)],1),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":_vm.$t('ui.BirthDate')}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{staticStyle:{"width":"70px"},attrs:{"defaultValue":"1"},model:{value:(_vm.dayVal),callback:function ($$v) {_vm.dayVal=$$v},expression:"dayVal"}},_vm._l((31),function(item){return _c('a-select-option',{key:item + 'year',attrs:{"value":31 - item + 1}},[_vm._v(_vm._s(31 - item + 1))])}),1),_c('a-select',{staticStyle:{"width":"70px"},attrs:{"defaultValue":"1"},model:{value:(_vm.monthVal),callback:function ($$v) {_vm.monthVal=$$v},expression:"monthVal"}},_vm._l((12),function(item){return _c('a-select-option',{key:item + 'year',attrs:{"value":12 - item + 1}},[_vm._v(_vm._s(12 - item + 1))])}),1),_c('a-select',{staticStyle:{"width":"90px"},attrs:{"defaultValue":"1970"},model:{value:(_vm.yearVal),callback:function ($$v) {_vm.yearVal=$$v},expression:"yearVal"}},_vm._l((100),function(item){return _c('a-select-option',{key:item + 'year',attrs:{"value":2010 - item}},[_vm._v(_vm._s(2010 - item))])}),1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.loading}},[_vm._v(_vm._s(_vm.$t("buttons.register")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }