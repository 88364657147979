<template>
  <section>
    <a-divider class="dividerTitle text-outline">{{
      $t("pages.registration.title")
    }}</a-divider>
    <!--  -->
    <SocialLogin />
    <!--  -->
    <div :style="{ 'margin-left': '20px', 'margin-right': '20px' }">
      <a-form :form="form" class="registration-form" @submit="handleSubmit">
        <div
          class="subtitle text-outline"
          :style="{
            'margin-left': 'auto !important',
            'margin-right': 'auto !important',
            width: 'fit-content',
          }"
        >
          {{ $t("pages.registration.subtitle") }}
        </div>
        <a-form-item
          v-bind="formItemLayout"
          label="E-mail"
          class="text-outline"
        >
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: this.$t('ui.provideValidEmail'),
                  },
                  {
                    required: true,
                    message: this.$t('ui.ProvideEmail'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          :label="$t('ui.Password')"
          class="text-outline"
        >
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ProvidePassword'),
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              },
            ]"
            :type="pwdVisible ? undefined : 'password'"
          >
            <a-icon
              slot="addonAfter"
              :type="pwdVisible ? 'eye' : 'eye-invisible'"
              @click="pwdVisible = !pwdVisible"
            />
          </a-input>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          :label="$t('ui.Password')"
          class="text-outline"
        >
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ConfirmPassword'),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            :type="pwdVisible ? undefined : 'password'"
            @blur="handleConfirmBlur"
          >
            <a-icon
              slot="addonAfter"
              :type="pwdVisible ? 'eye' : 'eye-invisible'"
              @click="pwdVisible = !pwdVisible"
            />
          </a-input>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" class="text-outline">
          <span slot="label">{{ $t("ui.FirstName") }}&nbsp;</span>
          <a-input
            v-decorator="[
              'firstname',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ProvideFirstName'),
                    whitespace: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" class="text-outline">
          <span slot="label">{{ $t("ui.SecondName") }}&nbsp;</span>
          <a-input
            v-decorator="[
              'secondname',
              {
                rules: [
                  {
                    required: true,
                    message: $t('ui.ProvideSecondName'),
                    whitespace: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" has-feedback class="text-outline">
          <span slot="label">{{ $t("ui.gender") }}&nbsp;</span>
          <a-select
            v-decorator="[
              'gender',
              {
                rules: [
                  { required: true, message: $t('ui.ProvideYourGender') },
                ],
              },
            ]"
            :placeholder="$t('ui.PleaseProvideYourGender')"
          >
            <a-select-option value="0">{{
              $t("ui.GenderMale")
            }}</a-select-option>
            <a-select-option value="1">{{
              $t("ui.GenderFemale")
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          :label="$t('ui.BirthDate')"
          class="text-outline"
        >
          <a-select defaultValue="1" style="width: 70px" v-model="dayVal">
            <a-select-option
              v-for="item in 31"
              :key="item + 'year'"
              :value="31 - item + 1"
              >{{ 31 - item + 1 }}</a-select-option
            >
          </a-select>
          <a-select defaultValue="1" style="width: 70px" v-model="monthVal">
            <a-select-option
              v-for="item in 12"
              :key="item + 'year'"
              :value="12 - item + 1"
              >{{ 12 - item + 1 }}</a-select-option
            >
          </a-select>
          <a-select defaultValue="1970" style="width: 90px" v-model="yearVal">
            <a-select-option
              v-for="item in 100"
              :key="item + 'year'"
              :value="2010 - item"
              >{{ 2010 - item }}</a-select-option
            >
          </a-select>
          <!-- <div>
            <a-date-picker
              @change="onChange"
              v-decorator="[
                'dateofbirth',
                {
                  rules: [
                    { required: true, message: $t('ui.ProvideBirthDate') }
                  ]
                }
              ]"
              style="width: 100%"
            />
          </div>-->
        </a-form-item>
        <!-- <a-form-item
      v-bind="formItemLayout"
      label="Captcha"
      extra="We must make sure that your are a human."
    >
      <a-row :gutter="8">
        <a-col :span="12">
          <a-input
            v-decorator="[
              'captcha',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input the captcha you got!'
                  }
                ]
              }
            ]"
          />
        </a-col>
        <a-col :span="12">
          <a-button>Get captcha</a-button>
        </a-col>
      </a-row>
        </a-form-item>-->
        <!-- <a-form-item v-bind="tailFormItemLayout">
          <a-checkbox v-decorator="['agreement', { valuePropName: 'checked' }]">
            {{ $t("ui.iveread") }}
            <router-link :to="('privacypolicy')" exact tag="a">
              {{ $t("ui.agreement") }}
            </router-link>
          </a-checkbox>
        </a-form-item>-->
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="primary" html-type="submit" :disabled="loading">{{
            $t("buttons.register")
          }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import SocialLogin from "./components/SocialLogin";

export default {
  components: {
    SocialLogin,
  },
  middleware: "notAuthenticated",
  data() {
    return {
      pwdVisible: false,
      monthVal: 1,
      dayVal: 1,
      yearVal: 1970,
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      loading: false,
      firstDate: [new Date(), new Date()],
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    const d = new Date();
    const pastYear = d.getFullYear() - 100;
    d.setFullYear(pastYear);
    this.firstDate = [d, new Date()];
  },
  methods: {
    onChange(value) {
      // console.log(value);
    },
    handleSubmit(e) {
      // console.log('handleSubmit');
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log("Received values of form: ", values);
          this.loading = true;
          const d = new Date(this.yearVal, this.monthVal - 1, this.dayVal);

          const response = await this["authCustom/registerAction"]({
            email: values.email,
            password: values.password,
            firstname: values.firstname,
            secondname: values.secondname,
            gender: values.gender,
            dateofbirth: d, // values.dateofbirth
          });
          this.loading = false;
          // console.log("response: ", response);

          this.$notification["success"]({
            message: this.$t("ui.SuccessOccurred"),
            description: this.$t("ui.SuccessOccurredConfirmEmail"),
            duration: 0,
          });
          
          this.$router.push("/");

          //   if (!response) {
          //     this.$notification["error"]({
          //       message: this.$t("ui.ErrorOccurred"),
          //       description: false,
          //     });
          //   } else {
          //     this.$notification["success"]({
          //       message: this.$t("ui.SuccessOccurred"),
          //       description: this.$t("ui.SuccessOccurredConfirmEmail"),
          //       duration: 0,
          //     });
          //     // this.$router.push("/");
          //   }
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.$t("ui.passwordsInconsistent"));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    ...mapActions(["authCustom/registerAction"]),
  },
};
</script>

<style scoped>
.registration-form {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.subtitle {
  margin-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}
</style>
